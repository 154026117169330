import * as React from "react";
import { SVGProps } from "react";

export const Loading = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
    <path
      fill="currentColor"
      d="M74.7 50.7c.1 5.6-1.9 11.3-5.5 15.8S60.3 74 54.6 75c-5.7 1.1-11.8 0-16.8-3-5.1-2.9-9.1-7.7-11-13.3-1.9-5.6-2-11.9.2-17.5s6.3-10.4 11.7-13.4c2.7-1.5 5.6-2.4 8.6-2.9 3-.4 6.1-.4 9.1.2 6 1.2 11.6 4.7 15.4 9.6 1 1.3.8 3.2-.5 4.2s-3.2.8-4.2-.5c0 0 0-.1-.1-.1-2.8-3.9-7-6.8-11.8-7.9-2.4-.6-4.8-.7-7.3-.5-2.4.3-4.8.9-7 2.1-4.4 2.2-8.1 6.1-10 10.7-2 4.6-2.2 10-.7 14.8 1.5 4.9 4.8 9.2 9.2 11.9 4.3 2.8 9.7 4 14.9 3.2 5.2-.7 10.1-3.3 13.6-7.3s5.6-9.2 5.7-14.6c0-.3.3-.6.6-.6.2 0 .5.3.5.6z"
    />
  </svg>
);
